<template>
  <div>
    <b-overlay :show="show" opacity="0.95" variant="dark">
      <b-card>
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <!-- <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">none</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
 
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group> -->
          </div>
        </b-card-body>
        <div v-if="!show">
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
        
          <template #cell(picture)="data">
            <div @click="detail_data(data.item.id)">
              <b-avatar :src="data.item.picture" size="55px" rounded />
            </div>
          </template>
          <template #cell(name)="data">
            <div @click="detail_data(data.item.id)">
              {{ data.item.displayName || data.item.name }}
            </div>
          </template>
          <template #cell(adddate)="data">
            <div @click="detail_data(data.item.id)">
              {{ data.item.adddate }}
            </div>
          </template>
          <template #cell(age)="data">
            <div @click="detail_data(data.item.id)">
              อายุ {{ data.item.age }} ปี
            </div>
          </template>
          <template #cell(friend_status)="data">
            <div @click="detail_data(data.item.id)">
              <div v-if="data.item.friend_status === 'Block'" >
              
                <b-badge variant="danger">
                  {{  data.item.friend_status}}
                </b-badge>

              </div>
              <div v-else>
         
                <b-badge variant="primary">
                  {{  data.item.friend_status}}
                </b-badge>
              </div>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button @click="detail_data(data.item.id)" variant="info">
              <feather-icon icon="AirplayIcon" />
            </b-button>
          </template>
        </b-table>
      </div>
      <div v-else>
        <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
      
        <template #cell(picture)="data">
          <div >
            <b-avatar :src="data.item.picture" size="55px" rounded />
          </div>
        </template>
        <template #cell(name)="data">
          <div >
            {{ (data.item.displayName || data.item.name).substring(0, 5) }}xxxxx
          </div>
        </template>
        <template #cell(adddate)="data">
          <div >
           xxxx-xx-xx xx:xx:xx
          </div>
        </template>
        <template #cell(age)="data">
          <div >
            อายุ xx ปี
          </div>
        </template>
        <template #cell(friend_status)="data">
          <div >
              <b-badge variant="danger">
                ไม่มีสิทธ์เข้าถึง
              </b-badge>
           
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button  variant="info">
            <feather-icon icon="AirplayIcon" />
          </b-button>
        </template>
      </b-table>
      </div>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <template v-slot:overlay>
        <div class="overlay-alert">สำหรับแพ็กเกจ Standard ขึ้นไป</div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from "bootstrap-vue";
import api from "@/api";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  data() {
    return {
      show: true,
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "picture", label: "โปร์ไฟล์" },
        { key: "name", label: "ชื่อ" },
        { key: "adddate", label: "วันที่สมัคร", sortable: true },
        { key: "age", label: "อายุ" },
        { key: "friend_status", label: "สถานะ" },
        { key: "actions", label: "ดูเพิ่มเติม" },
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          1: "online",
          2: "offline",
        },
        {
          1: "light-success",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    if (localStorage.getItem("packages") === "Pro") {
      this.show = false;
    } else if (localStorage.getItem("packages") === "Standard") {
      this.show = false;
    } else {
      this.show = true;
    }

    this.loaddata();
  },
  methods: {
    detail_data(id) {
      //   alert(id);
      this.$router.push("/profile/line");
      localStorage.setItem("user_id", id);
    },
    loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("users_register_line", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.items = response.data.data;
          this.totalRows = response.data.total;
        })
        .catch((error) => {
          this.$swal({
            title: "ไม่พบข้อมูล!",
            icon: "error",
            timer: 1500,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.badge-primary{
  background-color:rgb(14, 222, 14) !important
}
</style>
